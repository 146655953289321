import { prop } from 'ramda';
import { InfoWindowMarkerComponent } from '../components/GoogleMaps/InfoWindowMarkerComponent';
import { InfoWindowMarker, MapLocationData, MapLocationQuery } from '../types/google-maps.types';
import { axios } from '../bootstrap/axios.bootstrap';

export function createWindowMarker(locationData: MapLocationData, icon: string): InfoWindowMarker {
    return new InfoWindowMarkerComponent({
        position: locationData.coordinates,
        icon: {
            url: icon,
            scaledSize: new google.maps.Size(1.5, 1.5, 'rem', 'rem'),
            anchor: new google.maps.Point(0, 24),
        },
    }, {
        content: locationData.infoWindowHTML,
    });
}

export function getCompanyLocations(filters: Partial<MapLocationQuery> | FormData): Promise<MapLocationData[] | null | undefined> {
    return axios
        .post<MapLocationData[]>('/contact/locations', filters)
        .then(prop('data'));
}
