import type { MarkerClustererOptions } from '@google/markerclustererplus';

/**
 * Coordinates of the Aalberts main office.
 */
export const mainOfficeCoordinates = {
    lat: 52.089668,
    lng: 5.1069867,
};

export const centralOverviewCoordinates = {
    lat: 52.089668,
    lng: 10.1069867,
};

/**
 * Single location pin image
 * @type {string}
 */
export const mapPinImage = `${window.location.origin}/images/pin.png`;
export const mapFlagSvg = `${window.location.origin}/images/flags/flag-icon.svg`;

/**
 * Cluster image URL
 * @type {string}
 */
export const mapClusterImage = `${window.location.origin}/images/cluster.png`;

export const clustererDefaults: MarkerClustererOptions = {
    imagePath: mapClusterImage,
    styles: [
        {
            textColor: 'white',
            url: mapClusterImage,
            height: 30,
            width: 58,
            anchorText: [7, 2],
            textSize: 14,
            fontWeight: 'bold',
        },
    ],
};

export const googleMapStylesheet: google.maps.MapTypeStyle[] = [
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#444444',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                saturation: 37,
            },
            {
                lightness: 48,
            },
            {
                color: '#e85b66',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'simplified',
            },
            {
                color: '#5d5d5d',
            },
            {
                weight: 0.01,
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f2f2f2',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#d6e5eb',
            },
            {
                visibility: 'on',
            },
        ],
    },
];

export const googleMapsStyleSheetV2: google.maps.MapTypeStyle[] = [
    {
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#f5f5f5',
            },
        ],
    },
    {
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#ffffff',
            },
        ],
    },
    {
        'elementType': 'geometry.stroke',
        'stylers': [
            {
                'color': '#ff0000',
            },
        ],
    },
    {
        'elementType': 'labels',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#616161',
            },
        ],
    },
    {
        'elementType': 'labels.text.stroke',
        'stylers': [
            {
                'color': '#f5f5f5',
            },
        ],
    },
    {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'administrative.land_parcel',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'administrative.land_parcel',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#bdbdbd',
            },
        ],
    },
    {
        'featureType': 'administrative.neighborhood',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'poi',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'poi',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#eeeeee',
            },
        ],
    },
    {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#757575',
            },
        ],
    },
    {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#e5e5e5',
            },
        ],
    },
    {
        'featureType': 'poi.park',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#9e9e9e',
            },
        ],
    },
    {
        'featureType': 'road',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'road',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#ffffff',
            },
        ],
    },
    {
        'featureType': 'road',
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'road.arterial',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#757575',
            },
        ],
    },
    {
        'featureType': 'road.highway',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#dadada',
            },
        ],
    },
    {
        'featureType': 'road.highway',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#616161',
            },
        ],
    },
    {
        'featureType': 'road.local',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#9e9e9e',
            },
        ],
    },
    {
        'featureType': 'transit',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'transit.line',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#e5e5e5',
            },
        ],
    },
    {
        'featureType': 'transit.station',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#eeeeee',
            },
        ],
    },
    {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#f2f2f2',
            },
        ],
    },
    {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#f2f2f2',
            },
        ],
    },
];

export const googleMapsFootprintStyleSheet: google.maps.MapTypeStyle[] = [];
