import { Subject } from 'rxjs';
import { InfoWindowMarker } from '../../types/google-maps.types';

export class InfoWindowMarkerComponent implements InfoWindowMarker {

    public readonly clicks$ = new Subject<this>();
    public readonly marker: google.maps.Marker;
    public readonly infoWindow: google.maps.InfoWindow;

    constructor(
        private readonly markerOptions: google.maps.MarkerOptions,
        private readonly infoWindowOptions: google.maps.InfoWindowOptions,
    ) {
        this.marker = new google.maps.Marker(this.markerOptions);
        this.infoWindow = new google.maps.InfoWindow(this.infoWindowOptions);

        this.marker.addListener('click', () => this.clicks$.next(this));
    }

    public showWindow(): void {
        this.infoWindow.open(this.marker.getMap()!, this.marker);
    }

    public hideWindow(): void {
        this.infoWindow.close();
    }

    public attachMap(map: google.maps.Map): void {
        this.marker.setMap(map);
    }

    public detachMap(): void {
        this.marker.setMap(null);
    }

    public destroy(): void {
        this.clicks$.complete();
    }
}
